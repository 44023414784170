import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import ReactMarkdown from "react-markdown";
import { IoMdSend } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { ChatState, initialState, updateChatState } from "./chatState";
import { paths } from "./paths";
import TypingIndicator from "./TypingIndicator";
import {
  getActiveGptConversation,
  resetActiveGptChat,
  syncUserWithServer,
} from "src/features/profile/state/profileSlice";
import { useAppDispatch } from "src/common/state/hooks";
import { createChat, updateChat, resetChat } from "../state/chatbotSlice";

interface Message {
  text: string;
  sender: "bot" | "user";
}

interface Option {
  value: string;
  label: string;
  next?: string;
}

const Chatbot: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userActiveGptConversation = useSelector(getActiveGptConversation);

  const [chatState, setChatState] = useState<ChatState>(initialState);
  const [currentPath, setCurrentPath] = useState("metier");
  const [isBotTyping, setIsBotTyping] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [options, setOptions] = useState<Option[]>([]);
  const [userInput, setUserInput] = useState("");
  const [inputMode, setInputMode] = useState(false);
  const [isFirstMessage, setIsFirstMessage] = useState(true);

  const bottomRef = useRef<HTMLDivElement>(null);

  const loadPreviousConversation = () => {
    if (userActiveGptConversation) {
      setIsFirstMessage(false);
      const { conversation_metadata, conversation_data } = userActiveGptConversation;

      setChatState((prevState) => ({
        ...prevState,
        userFormResults: conversation_metadata.userFormResults,
      }));

      const savedMessages: Message[] = [];
      const { userPrompts } = conversation_metadata;
      const { output } = conversation_data;

      const totalMessages = Math.max(userPrompts.length, output.length);

      for (let i = 0; i < totalMessages; i++) {
        if (i < userPrompts.length) {
          savedMessages.push({ text: userPrompts[i], sender: "user" });
        }
        if (i < output.length) {
          savedMessages.push({ text: output[i], sender: "bot" });
        }
      }

      setMessages(savedMessages);
      setInputMode(true);
    } else {
      resetChatLocally();
    }
  };

  useEffect(() => {
    loadPreviousConversation();
  }, [userActiveGptConversation]);

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, isBotTyping]);

  const handleOptionSelect = (selectedOption: Option) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: selectedOption.label, sender: "user" },
    ]);
    setChatState((prevState) =>
      updateChatState(
        prevState,
        currentPath as keyof ChatState["userFormResults"],
        selectedOption.value,
      ),
    );
    setOptions([]);
    setIsBotTyping(true);

    setTimeout(() => {
      setIsBotTyping(false);

      if (selectedOption.next) {
        const nextPath = selectedOption.next;
        setCurrentPath(nextPath);
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: paths[nextPath].question, sender: "bot" },
        ]);
        setOptions(paths[nextPath]?.options || []);
      } else {
        setInputMode(true);
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: "Merci. Veuillez décrire votre problématique.", sender: "bot" },
        ]);
      }
    }, 300);
  };

  const handleUserPromptSubmit = async () => {
    if (!userInput.trim()) return;

    const userMessage = userInput;
    setUserInput("");
    setIsSubmitting(true);

    setMessages((prevMessages) => [
      ...prevMessages,
      { text: userMessage, sender: "user" },
      { text: "", sender: "bot" },
    ]);

    setIsBotTyping(true);

    // If it's the first message, create a new conversation; otherwise, update the chat
    if (isFirstMessage) {
      await dispatch(
        createChat({
          userPrompt: userMessage,
          userFormResults: chatState.userFormResults,
          cb: (chunk) => {
            setMessages((prevMessages) => {
              const updatedMessages = [...prevMessages];
              const lastMessageIndex = updatedMessages.length - 1;

              updatedMessages[lastMessageIndex] = {
                ...updatedMessages[lastMessageIndex],
                text: updatedMessages[lastMessageIndex].text + chunk,
              };

              return updatedMessages;
            });
          },
        }),
      );
      setIsFirstMessage(false); // Set to false after the first message
    } else {
      // For subsequent messages, use updateChat
      await dispatch(
        updateChat({
          userPrompt: userMessage,
          cb: (chunk) => {
            setMessages((prevMessages) => {
              const updatedMessages = [...prevMessages];
              const lastMessageIndex = updatedMessages.length - 1;

              updatedMessages[lastMessageIndex] = {
                ...updatedMessages[lastMessageIndex],
                text: updatedMessages[lastMessageIndex].text + chunk,
              };

              return updatedMessages;
            });
          },
        }),
      );
    }

    dispatch(syncUserWithServer({}));
    setIsSubmitting(false);
    setIsBotTyping(false);
  };

  const resetChatLocally = () => {
    setChatState(initialState);
    setCurrentPath("metier");
    setMessages([{ text: paths["metier"].question, sender: "bot" }]);
    setIsFirstMessage(true);
    setIsBotTyping(false);
    setOptions(paths["metier"].options);
    setInputMode(false);
    setUserInput("");
    dispatch(resetActiveGptChat());
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && userInput.trim() && !isSubmitting) {
      handleUserPromptSubmit();
    }
  };

  return (
    <div className="flex m-2 flex-col items-center">
      <button
        className="bg-gray-200 hover:bg-gray-300 p-1 rounded"
        onClick={() => {
          dispatch(resetChat({ cb: resetChatLocally }));
        }}
      >
        {t("pages.chat.reset")}
      </button>
      <div className="w-3/5 h-[80vh] mx-auto p-4 bg-white shadow-lg rounded-lg flex flex-col">
        <div className="chat-window bg-gray-100 p-4 flex-grow overflow-auto rounded-md flex flex-col space-y-4">
          {messages.map((message, index) => (
            <div
              key={index}
              className={`flex ${message.sender === "user" ? "justify-end" : "justify-start"}`}
            >
              {message?.text && (
                <div
                  className={`px-4 py-2 rounded-3xl ${
                    message.sender === "user"
                      ? "max-w-xs bg-blue-500 text-white"
                      : "bg-gray-300 text-black"
                  }`}
                >
                  {message.sender === "bot" ? (
                    <ReactMarkdown>{message.text}</ReactMarkdown>
                  ) : (
                    message.text
                  )}
                </div>
              )}
            </div>
          ))}
          {isBotTyping && (
            <div className="flex justify-start">
              <div className="px-4 py-2 rounded-3xl bg-gray-300 text-black">
                <TypingIndicator />
              </div>
            </div>
          )}
          {!isBotTyping && options.length > 0 && (
            <div className="flex flex-col space-y-2">
              {options.map((option) => (
                <button
                  key={option.value}
                  className="px-4 py-2 rounded-3xl bg-blue-500 text-white self-end"
                  onClick={() => handleOptionSelect(option)}
                >
                  {option.label}
                </button>
              ))}
            </div>
          )}
          <div ref={bottomRef} />
        </div>
        {inputMode && (
          <div className="flex justify-between items-center p-2 bg-white border-t">
            <div className="flex-grow px-4 py-2 rounded-3xl bg-blue-100 text-black">
              <input
                type="text"
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                onKeyDown={handleKeyDown}
                className="w-full bg-transparent outline-none"
                placeholder={t("pages.chat.inputPlaceholder")}
                disabled={isSubmitting}
              />
            </div>
            <button
              className="bg-blue-500 text-white w-12 h-12 rounded-md ml-2 flex items-center justify-center"
              onClick={handleUserPromptSubmit}
              disabled={!userInput || isSubmitting}
              style={{ cursor: userInput.length && !isSubmitting ? "default" : "not-allowed" }}
            >
              <IoMdSend size={24} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Chatbot;
