import { HomeIcon } from "@heroicons/react/outline";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getSelectedProjectGroup } from "../../../features/projectGroups/state/projectGroupsSlice";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavigationItem } from "./NavigationItem";

interface NavigationProps {
  titles: string;
}

const Breadcrumb = ({ titles }: NavigationProps) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [pathnames, setPathnames] = useState<string[]>([]);
  const [pathRoute, setPathRoute] = useState<string[]>([]);
  const selectedProjectGroup = useSelector(getSelectedProjectGroup);

  //For calculate the lengt paths
  const pathArray = pathname.split("/").filter(Boolean);

  const lengthPath = pathArray.length;
  //const for not display the Project groups in double

  useEffect(() => {
    if (pathArray[0] === "templates") {
      setPathRoute(["templates"]);
      setPathnames(["templates"]);
    } else if (lengthPath === 3) {
      // limit breadcrumb length to 2
      const pathArrayWithProjectGroupId = pathArray.slice(0, 2);
      setPathnames(pathArrayWithProjectGroupId);
      setPathRoute(pathArrayWithProjectGroupId);
    } else if (pathArray[0] === "medias") {
      setPathnames(["medias"]);
      setPathRoute(["medias"]);
    } else if (pathArray[0] === "project-groups" || pathArray[0] === "statistics") {
      const newArrayPaths = pathArray.slice(0, 3);
      setPathRoute(pathArray.slice(0, 3));
      newArrayPaths[1] = selectedProjectGroup?.name;
      setPathnames(newArrayPaths);
    } else {
      setPathnames(pathArray);
    }
  }, []);

  return (
    <nav className="flex mb-3" aria-label="Breadcrumb">
      <ol className="inline-flex items-center space-x-1 md:space-x-3">
        {pathnames.length ? (
          <li className="inline-flex items-center">
            <Link
              to={"/"}
              className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white "
            >
              <HomeIcon className="mr-2 w-4 h-4" />
              {t("general.home")}
            </Link>
          </li>
        ) : (
          <HomeIcon className="mr-2 w-4 h-4 inline-flex items-center text-sm font-medium text-gray-700" />
        )}

        {pathnames
          .filter((p) => p)
          .map((name, index) => {
            return (
              <NavigationItem
                key={`${index}_`}
                name={name}
                index={index}
                titles={titles}
                pathRoute={pathRoute}
                pathnames={pathnames}
                pathArray={pathArray}
                pathname={pathname}
                selectedProjectGroup={selectedProjectGroup}
              />
            );
          })}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
