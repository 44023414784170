import React from "react";
import { useTranslation } from "react-i18next";
import Navigation from "src/common/components/Navigation/Navigation";
import Chatbot from "src/features/chatbot/components/Chatbot";

const Chat: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="h-full">
      <Navigation title={`Wixar ${t("general.ai")}`} />
      <Chatbot />
    </div>
  );
};

export default Chat;
