import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IAvatar3d,
  IMoodleEnv,
  IOrganization,
  IUser,
  IUserOpenedTutorial,
} from "../../../model/model";
import axios from "axios";
import apiUrls from "../../../api";
import {
  getAuthFromLocalStorage,
  storeAuthInLocalStorage,
} from "../../../common/util/localStorage";
import { isTokenExpired } from "../../../common/util/auth";
import { IAppLoaderAction } from "../../../common/state/loaderHandleMiddleware";
import { RootState } from "../../../common/state/store";

export interface IProfile {
  currentUser?: IUser;
  organization?: IOrganization;
  avatar3d: IAvatar3d;
  moodleEnv?: IMoodleEnv;
}

export interface INewPassword {
  email?: string;
  password?: string;
  passwordConfirmation?: string;
}
export interface ICheckEmail {
  email: string;
  id?: number;
}

export const updateUser = createAsyncThunk(
  "profile/updateUser",
  async (user: IUser & IAppLoaderAction) => {
    const id = user?.id;
    let response;

    await axios
      .put(`${apiUrls.register.users}/${id}`, {
        ...user,
      })
      .then((res) => {
        if (res !== undefined) {
          response = res.data;

          const authFromLocalStorage = getAuthFromLocalStorage();
          if (
            authFromLocalStorage.user &&
            authFromLocalStorage.organization &&
            authFromLocalStorage.jwt &&
            authFromLocalStorage.isRemebered &&
            authFromLocalStorage.jwt !== null &&
            !isTokenExpired(authFromLocalStorage.jwt)
          ) {
            storeAuthInLocalStorage(
              res.data,
              res.data.organization,
              authFromLocalStorage.jwt,
              authFromLocalStorage.isRemebered,
            );
          }
        }
      })
      .catch((e) => {
        console.error(e);
        return e;
      });

    return response;
  },
);

export const syncUserWithServer = createAsyncThunk(
  "profile/syncUserWithServer",
  // eslint-disable-next-line no-empty-pattern
  async ({}: IAppLoaderAction) => {
    const authFromLocalStorage = getAuthFromLocalStorage();
    const userId = authFromLocalStorage?.user?.id;

    const res = await axios.get(`${apiUrls.register.users}/${userId}?populate=*`);
    return res.data as IUser;
  },
);

export const setCurrentUserAndOrg = createAsyncThunk(
  "profile/setCurrentUserAndOrg",
  // eslint-disable-next-line no-empty-pattern
  async ({}: IAppLoaderAction) => {
    let organization: IOrganization | undefined;
    let currentUser: IUser | undefined;
    const authFromLocalStorage = getAuthFromLocalStorage();
    if (
      authFromLocalStorage.user &&
      authFromLocalStorage.organization &&
      authFromLocalStorage.jwt &&
      authFromLocalStorage.isRemebered &&
      authFromLocalStorage.jwt !== null &&
      !isTokenExpired(authFromLocalStorage.jwt)
    ) {
      currentUser = authFromLocalStorage.user;
      organization = authFromLocalStorage.organization;
    } else {
      await axios
        .get(
          `${apiUrls.register.users}/me?populate=*&populate[0]=preferred_language&populate[organization]=admin&populate[organization]=source_logo`,
        )
        .then((res) => {
          currentUser = res.data;

          organization = res.data.organization;
        })
        .catch((e) => {
          console.error(e);
          return e;
        });
    }

    return { currentUser, organization };
  },
);

export const updateOrganization = createAsyncThunk(
  "auth/updateOrganization",
  async (organization: IOrganization & IAppLoaderAction) => {
    const idOrganization = organization?.id;
    let response: IOrganization | undefined;
    await axios
      .put(
        `${apiUrls.register.organization}/${idOrganization}?populate[admin][populate]=*&populate[source_logo][populate]=*`,
        {
          ...organization,
          admin: organization.admin?.id,
          source_logo: organization.source_logo?.id,
        },
      )
      .then((res) => {
        response = { ...res.data, admin: res.data.admin };

        const authFromLocalStorage = getAuthFromLocalStorage();
        if (
          authFromLocalStorage.user &&
          authFromLocalStorage.organization &&
          authFromLocalStorage.jwt &&
          authFromLocalStorage.isRemebered &&
          authFromLocalStorage.jwt !== null &&
          !isTokenExpired(authFromLocalStorage.jwt)
        ) {
          const temp: IOrganization = { ...res.data, admin: res.data.admin };

          storeAuthInLocalStorage(
            authFromLocalStorage.user,
            temp,
            authFromLocalStorage.jwt,
            authFromLocalStorage.isRemebered,
          );
        }
      })
      .catch((e) => {
        console.error(e);
        return e;
      });
    return response;
  },
);

export const checkPassword = createAsyncThunk(
  "profile/checkPassword",
  async ({ password }: any & IAppLoaderAction) => {
    let response: number | undefined;

    await axios
      .post(apiUrls.auth.checkPwd, {
        password,
      })
      .then((res) => {
        if (res !== undefined) {
          response = res.data;
        }
      })
      .catch((error) => {
        console.error(error);
      });

    return response;
  },
);

export const checkEmail = createAsyncThunk(
  "profile/checkEmail",
  async (user: ICheckEmail & IAppLoaderAction) => {
    const email = user.email;
    const id = user.id;
    let response: number | undefined;

    await axios
      .post(`${apiUrls.auth.checkEmail}/${id}`, {
        email,
      })
      .then((res) => {
        if (res !== undefined) {
          response = res.data;
        }
      })
      .catch((error) => {
        console.error(error);
      });

    return response;
  },
);

export const changePassword = createAsyncThunk(
  "profile/changePassword",
  async (data: INewPassword & IAppLoaderAction) => {
    let response;
    await axios
      .post(apiUrls.auth.changePasswordFromProfile, {
        email: data.email,
        password: data.password,
        passwordConfirmation: data.passwordConfirmation,
      })
      .then((res: any) => {
        response = res;
      })
      .catch((error) => {
        console.error(error.response.data.message[0].messages[0].message);
        response = { error: error.response.data.message[0].messages[0].message };
      });

    return response;
  },
);

export const rpmRender = createAsyncThunk(
  "profile/rpmRender",
  async ({ model }: any & IAppLoaderAction) => {
    const payload = JSON.stringify({
      model,
      scene: "halfbody-portrait-v1",
    });

    let response;

    await axios
      .post(`${apiUrls.readyPlayerMe}`, payload, { headers: { "Content-Type": "text/plain" } })
      .then((res) => {
        response = res.data.renders[0];
      })
      .catch((e) => {
        console.error("rpm error: ", e);
      });

    return response;
  },
);

export const changeHasAcceptedCGV = createAsyncThunk(
  "profile/changeHasAcceptedCGV",
  async ({ userId }: any & IAppLoaderAction) => {
    return await axios
      .put(`${apiUrls.register.users}/${userId}`, { has_accepted_cgv: 1 })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.error(error);
      });
  },
);

export const fetchMoodleEnv = createAsyncThunk(
  "profile/fetchMoodleEnv",
  async ({ organizationId }: any & IAppLoaderAction) => {
    return await axios
      .get(`${apiUrls.moodle.moodleEnv}?filters[organization_id][id]=${organizationId}`)
      .then((res) => {
        return res.data.length > 0 ? res.data[0] : undefined;
      })
      .catch((error) => {
        console.error(error);
      });
  },
);

export const generateMoodleEnv = createAsyncThunk(
  "profile/generateMoodleEnv",
  async ({ moodleUrl }: any & IAppLoaderAction) => {
    return await axios
      .post(`${apiUrls.moodle.generate}`, {
        moodleUrl,
      })
      .then((res) => {
        return res.data.moodleEnv;
      })
      .catch((error) => {
        console.error(error);
      });
  },
);

export const revokeMoodleEnv = createAsyncThunk(
  "profile/revokeMoodleEnv",
  // eslint-disable-next-line no-empty-pattern
  async ({}: any & IAppLoaderAction) => {
    return await axios
      .post(`${apiUrls.moodle.revoke}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.error(error);
      });
  },
);

export const userOpenedTutorialReset = createAsyncThunk(
  "profile/userOpenedTutorialReset",
  // eslint-disable-next-line no-empty-pattern
  async ({}: any & IAppLoaderAction) => {
    return await axios.post(`${apiUrls.userOpenedTutorialReset}`).then((res) => {
      return res.data;
    });
  },
);

export const setHasOpenedTutorial = createAsyncThunk(
  "profile/setUserOpenedTutorial",
  async ({
    userOpenedTutorialId,
    newValues,
  }: { userOpenedTutorialId: number; newValues: IUserOpenedTutorial } & IAppLoaderAction) => {
    if (newValues.id) {
      delete newValues.id;
    }

    return await axios
      .put(`${apiUrls.userOpenedTutorial}/${userOpenedTutorialId}`, { data: { ...newValues } })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.error(error);
      });
  },
);

export const getHasOpenedTutorial = createAsyncThunk(
  "profile/getUserOpenedTutorial",
  async ({ userOpenedTutorialId }: { userOpenedTutorialId: number } & IAppLoaderAction) => {
    return await axios
      .get(`${apiUrls.userOpenedTutorial}/${userOpenedTutorialId}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.error(error);
      });
  },
);
const initialState: IProfile = {
  currentUser: undefined,
  organization: undefined,
  avatar3d: { status: "loading", preview: "" },
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    clearProfile: (state: IProfile) => {
      (Object.keys(state) as Array<keyof typeof state>).forEach((key) => {
        (state[key] as any) = initialState[key];
      });
    },
    setProfile: (
      state: IProfile,
      action: PayloadAction<{ currentUser: IUser; organization: IOrganization }>,
    ) => {
      state.currentUser = action.payload.currentUser;
      state.organization = action.payload.organization;
    },
    setUserOrganization: (state: IProfile, action) => {
      if (state.currentUser !== undefined) {
        state.currentUser.organization = { ...action.payload, admin: action.payload.admin };
      }
    },
    updateFirstname: (state: IProfile, action) => {
      if (state.currentUser !== undefined) {
        state.currentUser.firstname = action.payload;
      }
    },
    updateLastname: (state: IProfile, action) => {
      if (state.currentUser !== undefined) {
        state.currentUser.lastname = action.payload;
      }
    },
    updateAvatar3dUrl: (state: IProfile, action) => {
      if (state.currentUser !== undefined) {
        state.currentUser.avatar3d_url = action.payload;
      }
    },
    updateEmail: (state: IProfile, action) => {
      if (state.currentUser !== undefined) {
        state.currentUser.email = action.payload;
      }
    },
    setAvatar: (state: IProfile, action) => {
      if (state.currentUser !== undefined) {
        state.currentUser.source_avatar = { ...action.payload };
      }
    },
    updateUserAvatar: (state: IProfile, action) => {
      const bingo = (user: any) => Number(user.source_avatar.id) === Number(action.payload.id);
      if (state.currentUser) {
        if (bingo(state.currentUser)) {
          state.currentUser.source_avatar = action.payload;
        }
      }
    },
    updateOrganizationLogo: (state: IProfile, action) => {
      const bingo = (organization: any) =>
        Number(organization.source_logo.id) === Number(action.payload.id);
      if (state.organization) {
        if (bingo(state.organization)) {
          state.organization.source_logo = action.payload;
        }
      }
    },
    resetActiveGptChat: (state: IProfile) => {
      state.currentUser.active_gpt_conversation = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setCurrentUserAndOrg.fulfilled, (state: IProfile, { payload }) => {
      state.organization = payload.organization;
      state.currentUser = payload.currentUser;
    });
    builder.addCase(updateUser.fulfilled, (state: IProfile, { payload }) => {
      state.currentUser = payload;
    });
    builder.addCase(updateOrganization.fulfilled, (state: IProfile, { payload }) => {
      state.organization = payload;
    });
    builder.addCase(rpmRender.pending, (state: IProfile) => {
      state.avatar3d.status = "loading";
    });
    builder.addCase(rpmRender.fulfilled, (state: IProfile, data: any) => {
      state.avatar3d.status = "success";
      state.avatar3d.preview = data.payload;
    });
    builder.addCase(rpmRender.rejected, (state: IProfile) => {
      state.avatar3d.status = "failed";
    });
    builder.addCase(changeHasAcceptedCGV.fulfilled, (state: IProfile) => {
      if (state.currentUser) {
        state.currentUser.has_accepted_cgv = true;
      }
    });
    builder.addCase(setHasOpenedTutorial.fulfilled, (state: IProfile, { payload }) => {
      if (state.currentUser) {
        state.currentUser.user_opened_tutorial = payload;
      }
    });
    builder.addCase(userOpenedTutorialReset.fulfilled, (state: IProfile, { payload }) => {
      if (state.currentUser) {
        state.currentUser.user_opened_tutorial = payload;
      }
    });
    builder.addCase(fetchMoodleEnv.fulfilled, (state: IProfile, { payload }) => {
      state.moodleEnv = payload;
    });
    builder.addCase(generateMoodleEnv.fulfilled, (state: IProfile, { payload }) => {
      state.moodleEnv = payload;
    });
    builder.addCase(revokeMoodleEnv.fulfilled, (state: IProfile) => {
      state.moodleEnv = undefined;
    });
    builder.addCase(syncUserWithServer.fulfilled, (state: IProfile, { payload }) => {
      state.currentUser = payload;
    });
  },
});

export const profileReducer = profileSlice.reducer;

export const getCurrentUser = (state: RootState) => state.profile.currentUser;
export const getCurrentOrganization = (state: RootState) => state.profile.organization;
export const getCurrentAvatar3d = (state: RootState) => state.profile.avatar3d;
export const getIsAdminOfOrganization = (state: RootState) =>
  Number(state.profile.organization?.admin?.id) === Number(state.profile.currentUser?.id);

export const getUserOpenedTutorial = (state: RootState) =>
  state.profile.currentUser?.user_opened_tutorial;

export const getActiveGptConversation = (state: RootState) =>
  state.profile.currentUser?.active_gpt_conversation;

export const getMoodleEnv = (state: RootState) => state.profile.moodleEnv;

export const {
  updateFirstname,
  updateLastname,
  updateEmail,
  setAvatar,
  setUserOrganization,
  updateAvatar3dUrl,
  clearProfile,
  setProfile,
  updateUserAvatar,
  updateOrganizationLogo,
  resetActiveGptChat,
} = profileSlice.actions;
