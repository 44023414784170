import { useLocation, useNavigate } from "react-router-dom";
import { SearchProjectGroups } from "./ProjectGroupSearch";
import { ProjectGroupFilter } from "./ProjectGroupFilter";
import Breadcrumb from "./Breadcrumb";
import NavigationTabs from "./NavigationTabs";
import { useTranslation } from "react-i18next";
import { GoBackButton } from "../GoBackButton/GoBackButton";
import Icon from "../Icon/Icon";
import { useSelector } from "react-redux";
import { getSelectedProjectGroup } from "src/features/projectGroups/state/projectGroupsSlice";
import { setEdited as setEditedProject } from "src/features/projects/state/projectsSlice";
import { useAppDispatch } from "src/common/state/hooks";
import { Tooltip } from "@mui/material";
import { getShowStandardModal } from "src/common/state/slice/modal/modalSlice";

interface NavigationProps {
  tabs?: any;
  buttons?: React.ReactNode;
  title: string;
  isProjectView?: boolean;
  isProjectGroupView?: boolean;
  titles?: string;
  tagsBar?: React.ReactNode;
  showGoBackButton?: boolean;
  showSearchBar?: boolean;
  backDepth?: number; // for example a depth of 2 will navigate to "../.."
  backCustomRedirectTo?: string;
}

const Navigation = ({
  tabs,
  buttons,
  tagsBar,
  title,
  isProjectView = false,
  isProjectGroupView = false,
  showGoBackButton = false,
  showSearchBar = true,
  backDepth = 2,
  backCustomRedirectTo = "",
}: NavigationProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const currentProjectGroup = useSelector(getSelectedProjectGroup);

  const pathArray = pathname.split("/").filter(Boolean);

  const forDisplayProjectGroup = pathArray[0] === "project-groups" && pathArray.length === 1;
  const forDisplayProject = pathArray[2] === "list" && pathArray.length === 3;
  const forStatistics = pathArray[0] === "statistics";

  // Fix WIXAR-3864
  const isGraphNodePath = /^\/?project-groups\/\d+\/list\/\d+\/graph\/node\/[^/]+\/\d+\/?$/.test(
    pathname,
  );
  const showStandardModal = useSelector(getShowStandardModal);
  const isInsideModalGallery =
    showStandardModal.show === true && showStandardModal.id === "modal-gallery";

  return (
    <div
      id="navbar"
      className={`sticky top-0 w-full block bg-slate-100 z-40 ${
        tabs ? "" : "border-b border-gray-200"
      }`}
    >
      <div className="flex mx-6 pt-4 justify-between mb-2 h-2/3">
        <div className="block">
          <Breadcrumb titles={title} />
          <div className="flex items-center">
            {showGoBackButton && (
              <div className="onboardingHowToAddScene-step-5">
                <GoBackButton
                  className="min-w-0 mr-2"
                  depth={backDepth}
                  backCustomRedirectTo={backCustomRedirectTo}
                />
              </div>
            )}
            <h3
              className={`${tabs ? "mt-2 mb-2" : "py-4"} text-3xl font-medium cursor-default`}
              style={{ caretColor: "transparent" }}
            >
              {isGraphNodePath && isInsideModalGallery ? "" : title}
            </h3>
            <div>{tagsBar}</div>
            {isProjectGroupView && (
              <Tooltip title={t("tooltip.modifyCourse")}>
                <button
                  className="w-7 h-7 ml-2 mb-1 cursor-pointer fill-green-500"
                  onClick={() => {
                    navigate(`/project-groups/${currentProjectGroup.id}/edit`);
                  }}
                >
                  <Icon icon={"PencilAltIcon"} className={"stroke-green-500"} />
                </button>
              </Tooltip>
            )}
            {isProjectView && (
              <Tooltip title={t("tooltip.modifyModule")}>
                <button
                  className="w-7 h-7 ml-2 mb-1 cursor-pointer fill-green-500"
                  onClick={() => {
                    dispatch(setEditedProject());
                    navigate("edit");
                  }}
                >
                  <Icon icon={"PencilAltIcon"} className={"stroke-green-500"} />
                </button>
              </Tooltip>
            )}
          </div>
        </div>
        {showSearchBar && (
          <div
            className={`absolute bottom-8 right-12 ${
              forStatistics ? "top-[-1.5vh]" : "lg:top-4"
            } flex flex-col space-y-2 min-w-[150px]`}
          >
            <div className="flex mr-16">
              {forDisplayProjectGroup && <SearchProjectGroups isProjectGroup />}
              {forDisplayProjectGroup && <ProjectGroupFilter />}
            </div>
            <div className="mr-16">
              {forDisplayProject && <SearchProjectGroups isProjectGroup={false} />}
            </div>
            {buttons}
          </div>
        )}
      </div>
      {tabs && <NavigationTabs tabs={tabs} />}
    </div>
  );
};

export default Navigation;
