import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SearchAutocomplete } from "src/common/components/SearchAutocomplete/SearchAutocomplete";
import { IThemeInfo } from "src/model/model";
import SortableDropdown from "src/common/components/Input/SortableDropdown";

interface ThemesViewProps {
  publicThemes: IThemeInfo[];
  privatesThemes: IThemeInfo[];
  onNewThemeClick: () => void;
  onThemeClick: (theme: IThemeInfo) => void;
  onThemeMenuClick: (item: any, index: number) => void;
  componentType: "list" | "radio";
  selectedThemeId: number | null;
  setSelectedThemeId: (id: number | null) => void;
  selectedRadioButton?: number | null;
  setSelectedRadioButton?: (id: number | null) => void;
  ThemeItemComponent: React.ComponentType<any>;
}

const ThemesView = ({
  publicThemes,
  privatesThemes,
  onNewThemeClick,
  onThemeClick,
  onThemeMenuClick,
  componentType, // can be "list" or "radio"
  selectedThemeId,
  setSelectedThemeId,
  selectedRadioButton,
  setSelectedRadioButton,
  ThemeItemComponent,
}: ThemesViewProps) => {
  const { t } = useTranslation();
  const [filteredPrivatesThemes, setFilteredPrivatesThemes] = useState<IThemeInfo[]>([]);
  const [filteredPublicThemes, setFilteredPublicThemes] = useState<IThemeInfo[]>([]);
  const [showPrivateCollapseButton, setShowPrivateCollapseButton] = useState(false);
  const [showPublicCollapseButton, setShowPublicCollapseButton] = useState(false);
  const [privateCollapsed, setPrivateCollapsed] = useState(true);
  const [publicCollapsed, setPublicCollapsed] = useState(true);
  const privateThemesContainerRef = useRef(null);
  const publicThemesContainerRef = useRef(null);

  const [filterByText, setFilterByText] = useState("");
  const [filterByOrder, setFilterByOrder] = useState("date_desc"); // ["date_desc", "date_asc", "alphanum_desc", "alphanum_asc"]

  const handleTogglePrivateCollapse = () => {
    setPrivateCollapsed(!privateCollapsed);
  };

  const handleTogglePublicCollapse = () => {
    setPublicCollapsed(!publicCollapsed);
  };

  const itemHeight = 208;
  const additionalHeight = componentType === "radio" ? 48 : 0;
  const allPaddingsHight = 68;
  const maxVisibleHeight = 2 * itemHeight + allPaddingsHight + additionalHeight;

  const checkContainerHeight = () => {
    if (privateThemesContainerRef.current) {
      const containerHeight = privateThemesContainerRef.current.getBoundingClientRect().height;
      setShowPrivateCollapseButton(containerHeight > maxVisibleHeight);
    }
    if (publicThemesContainerRef.current) {
      const containerHeight = publicThemesContainerRef.current.getBoundingClientRect().height;
      setShowPublicCollapseButton(containerHeight > maxVisibleHeight);
    }
  };

  useEffect(() => {
    checkContainerHeight();
    window.addEventListener("resize", checkContainerHeight);
    return () => {
      window.removeEventListener("resize", checkContainerHeight);
    };
  }, [filteredPrivatesThemes]);

  useEffect(() => {
    checkContainerHeight();
  }, [privateCollapsed, publicCollapsed]);

  const filterThemes = (themesToFilter: IThemeInfo[]) => {
    let localFilteredThemes = [...themesToFilter];

    if (filterByText !== "") {
      localFilteredThemes = localFilteredThemes.filter((theme) =>
        theme.name.toLowerCase().includes(filterByText.toLowerCase()),
      );
    }

    if (filterByOrder === "date_desc") {
      localFilteredThemes = localFilteredThemes.sort(
        (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
      );
    }
    if (filterByOrder === "date_asc") {
      localFilteredThemes = localFilteredThemes.sort(
        (a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime(),
      );
    }
    if (filterByOrder === "alphanum_desc") {
      localFilteredThemes = localFilteredThemes.sort((a, b) => a.name.localeCompare(b.name));
    }
    if (filterByOrder === "alphanum_asc") {
      localFilteredThemes = localFilteredThemes.sort((a, b) => b.name.localeCompare(a.name));
    }

    return localFilteredThemes;
  };

  useEffect(() => {
    const filterAndSetThemes = () => {
      const filteredPrivateThemes = filterThemes(privatesThemes);
      const filteredPublicThemes = filterThemes(publicThemes);

      setFilteredPrivatesThemes(filteredPrivateThemes);
      setFilteredPublicThemes(filteredPublicThemes);

      if (setSelectedRadioButton) {
        const firstFilteredPrivateThemeId =
          filteredPrivateThemes.length > 0 ? filteredPrivateThemes[0].id : null;
        const firstFilteredPublicThemeId =
          filteredPublicThemes.length > 0 ? filteredPublicThemes[0].id : null;
        const defaultThemeId = firstFilteredPrivateThemeId || firstFilteredPublicThemeId || 0;

        setSelectedRadioButton(defaultThemeId);
        setSelectedThemeId(defaultThemeId);
      }
    };

    filterAndSetThemes();
  }, [privatesThemes, publicThemes, filterByText, filterByOrder]);

  const memoizedItems = useMemo(() => {
    return [...publicThemes, ...privatesThemes].map((theme, index) => ({
      id: theme.id || index,
      name: theme.name,
    }));
  }, [publicThemes, privatesThemes]);

  return (
    <div className="w-full overflow-auto h-full personalize-scroll-visible">
      <div className="flex flex-col grow w-full justify-between h-full">
        <div className="whitespace-no-wrap w-full space-y-6 my-6">
          <div className="px-12 flex">
            <div className="mt-1">
              <SearchAutocomplete
                items={memoizedItems}
                handleOnSearch={setFilterByText}
                handleOnSelect={(item) => setFilterByText(item.name)}
              />
            </div>
            <div className="pl-6">
              <SortableDropdown onChangeCb={(e) => setFilterByOrder(e.target.value)} />
            </div>
          </div>
          <div className="w-full overflow-x-auto">
            <div className="px-12 flex w-full">
              <h3 className="flex font-medium text-lg items-center">
                {t("pages.createProjectGroup.customThemes")}
              </h3>
              {showPrivateCollapseButton && (
                <div className="flex ml-4">
                  <button
                    className="text-green-500 text-sm"
                    onClick={(e) => {
                      e.preventDefault();
                      handleTogglePrivateCollapse();
                    }}
                  >
                    {privateCollapsed ? t("general.showMore") : t("general.showLess")}
                  </button>
                </div>
              )}
            </div>
            <div
              ref={privateThemesContainerRef}
              className="px-12 py-6 grid gap-6 sm:gap-5 w-full personalize-scroll-visible"
              style={{
                gridTemplateColumns: "repeat(auto-fit, minmax(200px, max-content))",
                maxHeight: privateCollapsed
                  ? `calc(2 * ${itemHeight}px + ${allPaddingsHight}px + ${additionalHeight}px + 0.1px)`
                  : "none",
                overflowY: "hidden",
              }}
            >
              <div
                className="w-52 h-52 p-2 cursor-pointer relative"
                onClick={(e) => {
                  e.preventDefault();
                  onNewThemeClick();
                }}
              >
                <img
                  src="https://s3.eu-west-3.amazonaws.com/storage.wixar.io/newThemeButton.png"
                  className="w-full h-full"
                />
                <div className="flex justify-center absolute top-1/2 left-1/2 translate-y-[-50%] translate-x-[-50%] w-3/4 bg-green-500 rounded-full font-medium text-white py-2 px-3">
                  {t("pages.createProjectGroup.newTheme")}
                </div>
              </div>
              {filteredPrivatesThemes?.map((theme) => (
                <ThemeItemComponent
                  key={`chooseThemeListPrivate_${theme.id}`}
                  theme={theme}
                  onClick={onThemeClick}
                  threeDotsMenuItems={[
                    {
                      menuItemText: t("pages.themeEditor.createthemeWithThisBase"),
                      icon: "PlusIcon",
                      itemId: "createthemeWithThisBase",
                      themeId: theme.id,
                    },
                    {
                      menuItemText: t("pages.themeEditor.edit"),
                      icon: "PencilAltIcon",
                      itemId: "editTheme",
                      themeId: theme.id,
                    },
                    {
                      menuItemText: t("general.delete"),
                      icon: "TrashIcon",
                      itemId: "deleteTheme",
                      themeId: theme.id,
                    },
                  ]}
                  threeDotsMenuHandleClick={onThemeMenuClick}
                  componentType={componentType}
                  selectedThemeId={selectedThemeId}
                  setSelectedThemeId={setSelectedThemeId}
                  inputElementParams={{
                    checked: selectedRadioButton === theme.id,
                  }}
                />
              ))}
            </div>
          </div>
          <div className="w-full overflow-x-auto">
            <div className="px-12 flex w-full">
              <h3 className="flex font-medium text-lg items-center">
                {t("pages.createProjectGroup.publicThemes")}
              </h3>
              {showPublicCollapseButton && (
                <div className="flex ml-4">
                  <button
                    className="text-green-500 text-sm"
                    onClick={(e) => {
                      e.preventDefault();
                      handleTogglePublicCollapse();
                    }}
                  >
                    {publicCollapsed ? t("general.showMore") : t("general.showLess")}
                  </button>
                </div>
              )}
            </div>
            <div
              ref={publicThemesContainerRef}
              className="px-12 py-6 grid gap-6 sm:gap-5 w-full personalize-scroll-visible"
              style={{
                gridTemplateColumns: "repeat(auto-fit, minmax(200px, max-content))",
                maxHeight: publicCollapsed
                  ? `calc(2 * ${itemHeight}px + ${allPaddingsHight}px + ${additionalHeight}px + 0.1px)`
                  : "none",
                overflowY: "hidden",
              }}
            >
              {filteredPublicThemes && filteredPublicThemes.length === 0 ? (
                <p className="w-96">{t("pages.themeEditor.notFound")}</p>
              ) : (
                <>
                  {filteredPublicThemes?.map((theme) => (
                    <ThemeItemComponent
                      key={`chooseThemeListPublic_${theme.id}`}
                      theme={theme}
                      onClick={onThemeClick}
                      threeDotsMenuItems={[
                        {
                          menuItemText: t("pages.themeEditor.createthemeWithThisBase"),
                          icon: "PlusIcon",
                          itemId: "createthemeWithThisBase",
                          themeId: theme.id,
                        },
                      ]}
                      threeDotsMenuHandleClick={onThemeMenuClick}
                      componentType={componentType}
                      selectedThemeId={selectedThemeId}
                      setSelectedThemeId={setSelectedThemeId}
                      inputElementParams={{
                        checked: selectedRadioButton === theme.id,
                      }}
                    />
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThemesView;
