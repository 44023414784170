import { createAsyncThunk, createSlice, PayloadAction, current } from "@reduxjs/toolkit";
import axios from "axios";
import apiUrls from "../../../api";
import { RootState } from "../../../common/state/store";
import {
  IBuild,
  IProject,
  IProjectsPermissions,
  IProjectTag,
  ITemplateProject,
  ICurrentProject,
} from "../../../model/model";
import { IAppLoaderAction } from "../../../common/state/loaderHandleMiddleware";
import { IMedia } from "../../../model/unityObject";

export interface IProjectsState {
  all: Array<IProject>;
  templates: Array<ITemplateProject>;
  currentProject: IProject;
  currentTemplateId: number;
  projectTags: Array<IProjectTag>;
  newProject: INewProjectFormValues;
  currentProjectPermissions: Array<IProjectsPermissions>;
  currentProjectInteractions: ICurrentProject;
  currentProjectBuilds: IBuild[];
  edited: {
    name: string;
    source_thumbnail: IMedia;
    language: number;
    enabledLanguagesIds: string[];
  };
  currentModeListProject: boolean;
}

export interface INewProjectFormValues {
  projectId: number;
  newName: string;
  languageId: number;
  thumbnailId: number;
  enabledLanguagesIds: string[];
  projectGroupId: number;
  crated_by?: string;
}

const initialState: IProjectsState = {
  currentProjectPermissions: [],
  newProject: {
    projectId: 0,
    languageId: 0,
    newName: "",
    thumbnailId: 0,
    projectGroupId: 0,
    enabledLanguagesIds: [],
  },
  currentTemplateId: 390, // Default to 'From Scratch'
  projectTags: [],
  all: [],
  templates: [],
  currentProject: {
    id: 0,
    name: "",
    need_login: false,
    is_allowing_collab: false,
    status: "Edit",
    organization: {
      id: 0,
      name: "",
      createdAt: "",
      updatedAt: "",
      legal_name: "",
      address_locality: "",
      address_region: "",
      address_country: "",
      phone: "",
      postal_code: "",
      email: "",
      siret: "",
      street_address: "",
      source_logo: {
        id: 0,
        s3_url: "",
        createdAt: "",
        updatedAt: "",
        organization: 0,
        type: "image",
        name: "",
        filename: "",
      },
      admin: {
        avatar3d_url: "",
        blocked: false,
        confirmationToken: null,
        confirmed: true,
        createdAt: "",
        email: "",
        firstname: "",
        has_accepted_cgv: true,
        id: 0,
        lastname: "",
        password: "",
        provider: null,
        resetPasswordToken: null,
        updatedAt: "",
        username: "",
      },
    },
    is_template: false,
    createdAt: "",
    updatedAt: "",
    uuid: "",
    builds: [],
    language: {
      id: 1,
      name: "French",
    },
    source_thumbnail: {
      id: 0,
      s3_url: "",
      createdAt: "",
      updatedAt: "",
      filename: "",
      label: "",
      organization: {
        id: 0,
        name: "",
        createdAt: "",
        updatedAt: "",
        legal_name: "",
        address_locality: "",
        address_region: "",
        address_country: "",
        phone: "",
        postal_code: "",
        email: "",
        siret: "",
        street_address: "",
        source_logo: {
          id: 0,
          s3_url: "",
          createdAt: "",
          updatedAt: "",
          organization: 0,
          type: "image",
          name: "",
          filename: "",
        },
        admin: {
          avatar3d_url: "",
          blocked: false,
          confirmationToken: null,
          confirmed: true,
          createdAt: "",
          email: "",
          firstname: "",
          has_accepted_cgv: true,
          id: 0,
          lastname: "",
          password: "",
          provider: null,
          resetPasswordToken: null,
          updatedAt: "",
          username: "",
        },
      },
      type: "image",
      name: "",
      upload_status: "completed",
    },
    source_logo: {
      id: 0,
      s3_url: "",
      createdAt: "",
      updatedAt: "",
      filename: "",
      label: "",
      organization: {
        id: 0,
        name: "",
        created_at: "",
        updated_at: "",
        legal_name: "",
        address_locality: "",
        address_region: "",
        address_country: "",
        phone: "",
        postal_code: "",
        email: "",
        siret: "",
        street_address: "",
        source_logo: {
          id: 0,
          s3_url: "",
          created_at: "",
          updated_at: "",
          organization: 0,
          type: "image",
          name: "",
          filename: "",
        },
        admin: {
          avatar3d_url: "",
          blocked: false,
          confirmationToken: null,
          confirmed: true,
          createdAt: "",
          email: "",
          firstname: "",
          has_accepted_cgv: true,
          id: 0,
          lastname: "",
          password: "",
          provider: null,
          resetPasswordToken: null,
          updatedAt: "",
          username: "",
        },
      },
      type: "image",
      name: "",
      upload_status: "completed",
    },
    enabled_languages: [],
    permissions: [],
    cloned_from: null,
    id_build_vr: null,
    id_build_mobile: null,
    id_build_webgl: null,
    id_background_music: null,
    background_music_volume: 1,
    is_deleted: false,
    is_user_template: false,
  },
  currentProjectInteractions: {
    projectId: 0,
    interactions: [],
    sizeInMbs: 0,
  },
  currentProjectBuilds: [],
  edited: {
    name: "",
    source_thumbnail: {
      createdAt: "",
      id: 0,
      name: "",
      label: "",
      organization: 0,
      s3_url: "",
      type: "image",
      updatedAt: "",
      filename: "",
      size_in_bytes: 0,
      media_360_tag: "",
      MD5: "",
      upload_status: "completed",
    },
    language: 1,
    enabledLanguagesIds: [],
  },
  currentModeListProject: true,
};

// eslint-disable-next-line no-empty-pattern
export const getAllProjects = createAsyncThunk("projects/all", async ({}: IAppLoaderAction) => {
  const response = (await axios.get)<Array<IProject>>(`${apiUrls.projects.allWithUserTemplates}`);
  return { data: (await response).data };
});

export const getProjectTemplates = createAsyncThunk(
  "projects/templates",
  // eslint-disable-next-line no-empty-pattern
  async ({}: IAppLoaderAction) => {
    const response = (await axios.get)<Array<ITemplateProject>>(
      `${apiUrls.projects.templates}?populate[source_screenshot]=*&populate[project]=*`,
    );
    return { data: (await response).data };
  },
);
export const getCurrentInteractions = createAsyncThunk(
  "projects/currentInteractions",
  async ({ projectId }: { projectId: number } & IAppLoaderAction) => {
    const response = (await axios.get)<ICurrentProject>(
      `${apiUrls.projects.getMetaDatas}?id=${projectId}`,
    );
    return { data: (await response).data };
  },
);

export const getProjectSize = createAsyncThunk(
  "projects/getProjectSize",
  async ({ projectId }: { projectId: number } & IAppLoaderAction) => {
    const response = (await axios.get)(`${apiUrls.projects.projectSize}?id=${projectId}`);
    return { data: (await response).data };
  },
);

export const getProjectTagList = createAsyncThunk(
  "projects/tagList",
  // eslint-disable-next-line no-empty-pattern
  async ({}: IAppLoaderAction) => {
    const response = (await axios.get)<Array<IProjectTag>>(
      `${apiUrls.projects.projectTags}?sort[0]=id:asc&populate[0]=projects`,
    );
    return { data: (await response).data };
  },
);

export const duplicateProject = createAsyncThunk(
  "projects/duplicateProject",
  async (
    {
      project,
      thumbnail,
      projectGroup,
      newName,
      enabledLanguagesIds,
    }: {
      project: number;
      thumbnail: number;
      projectGroup: number;
      newName: string;
      enabledLanguagesIds: string[];
    } & IAppLoaderAction,
    { rejectWithValue },
  ) => {
    try {
      const languageValue = enabledLanguagesIds[0];
      const enabledLanguages = enabledLanguagesIds.join(",");
      const response = axios.post(
        `${apiUrls.projects.duplicateProject}?project=${project}&projectGroup=${projectGroup}&thumbnail=${thumbnail}&newName=${newName}&makeTemplate=false&language=${languageValue}&enabledLanguagesIds=${enabledLanguages}`,
      );
      return { data: (await response).data.newProject };
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err?.response?.data);
      } else {
        throw err;
      }
    }
  },
);

export const makeTemplate = createAsyncThunk(
  "projects/makeTemplate",
  async (
    {
      project,
      thumbnail,
      newName,
    }: {
      project: number;
      thumbnail: number;
      newName: string;
    } & IAppLoaderAction,
    { rejectWithValue },
  ) => {
    try {
      const response = axios.post(
        `${apiUrls.projects.duplicateProject}?project=${project}&thumbnail=${thumbnail}&newName=${newName}&makeTemplate=true`,
      );
      return { data: (await response).data.newProject };
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err?.response?.data);
      } else {
        throw err;
      }
    }
  },
);

export const setProjectTeam = createAsyncThunk(
  "projects/permissons",
  async (
    {
      inviterId,
      projectId = -1,
      projectGroupId = -1,
      inviteeId,
    }: {
      inviteeId: number;
      inviterId: number;
      projectId?: number;
      projectGroupId?: number;
    } & IAppLoaderAction,
    { rejectWithValue },
  ) => {
    try {
      const response = (await axios.post)(`${apiUrls.projects.inviteExistingUser}`, {
        inviterId,
        inviteeId,
        projectId,
        projectGroupId,
      });

      return { data: (await response).data };
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err?.response?.data);
      } else {
        throw err;
      }
    }
  },
);

export const deleteProjectPermissons = createAsyncThunk(
  "projects/deletePermissons",
  async (
    { permission }: { permission: IProjectsPermissions } & IAppLoaderAction,
    { rejectWithValue },
  ) => {
    const { id } = permission;
    try {
      const response = (await axios.delete)(`${apiUrls.projects.projectUserPermissions}/${id}`);
      return { data: (await response).data };
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err?.response?.data);
      } else {
        throw err;
      }
    }
  },
);

export const setBackgroundMusic = createAsyncThunk(
  "projects/setBackgroundMusic",
  async (
    {
      projectId,
      id_background_music,
      background_music_volume,
    }: {
      projectId: number | string;
      id_background_music: number;
      background_music_volume: number;
    } & IAppLoaderAction,
    { rejectWithValue },
  ) => {
    try {
      const response = (await axios.put)(`${apiUrls.projects.all}/${projectId}`, {
        id_background_music,
        background_music_volume,
      });

      return { data: (await response).data };
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err?.response?.data);
      } else {
        throw err;
      }
    }
  },
);

export const updateProject = createAsyncThunk(
  "project/updateProject",
  async ({
    id,
    payload,
  }: {
    id: number;
    payload: any;
  } & IAppLoaderAction) => {
    return await axios
      .put(`${apiUrls.projects.all}/${id}`, {
        language: payload.enabled_languages[0],
        ...payload,
      })
      .then((res) => {
        return res.data;
      })
      .catch((e) => console.error(e));
  },
);

export const deleteNode = createAsyncThunk(
  "project/deleteNode",
  async ({
    contentId,
  }: {
    contentId: number;
  } & IAppLoaderAction) => {
    return await axios
      .delete(`${apiUrls.projects.deleteNode}?content=${contentId}`)
      .then((res) => {
        return res.data;
      })
      .catch((e) => console.error(e));
  },
);

export const fetchCurrentProjectBuilds = createAsyncThunk(
  "project/fetchCurrentProjectBuilds",
  async ({
    projectGroupId,
  }: {
    projectGroupId: number;
  } & IAppLoaderAction) => {
    let response: any = {};
    await axios
      .get(`${apiUrls.builds}?project_group=${projectGroupId}&populate=project_group`)
      .then((res) => {
        response = (res.data as []).map((elt: any) => ({
          id: elt.id,
          name: elt.name,
          s3_url: elt.s3_url,
          target_platform: elt.target_platform,
          version: elt.version,
          created_at: elt.created_at,
          updated_at: elt.updated_at,
        }));
      })
      .catch((e) => {
        console.error(e);
      });
    return response;
  },
);
export const createNewScene = createAsyncThunk(
  "projects/createNewScene",
  async (
    {
      projectId,
      mediaId,
      model3d,
      cb,
      sceneName,
    }: {
      projectId: number;
      mediaId: number;
      model3d?: number;
      cb?: (data: any) => void;
      sceneName?: string;
    } & IAppLoaderAction,
    { rejectWithValue },
  ) => {
    try {
      const response = (await axios.post)(
        `${apiUrls.projects.createScene}?projectId=${projectId}&mediaId=${mediaId}&model3d=${model3d}&sceneName=${sceneName}`,
      );

      if (cb) cb((await response).data);
      return { data: (await response).data };
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err?.response?.data);
      } else {
        throw err;
      }
    }
  },
);

export const deleteProject = createAsyncThunk(
  "projects/delete",
  async ({ id }: { id: number } & IAppLoaderAction) => {
    const response = (await axios.put)<IProject>(`${apiUrls.projects.all}/${id}`, {
      is_deleted: true,
    });
    return { data: (await response).data };
  },
);

export const deleteMultipleProject = createAsyncThunk(
  "projects/deleteMultipleProjects",
  async ({ projectsListId }: { projectsListId: Array<number> } & IAppLoaderAction) => {
    const response = await axios.delete<Array<number>>(
      `${apiUrls.projects.multipleProjects}?projectsListId=${projectsListId.toString()}`,
    );
    return { data: response.data };
  },
);

export const getProjectPermissionsFromServer = createAsyncThunk(
  "projects/getProjectPermissionsFromServer",
  // eslint-disable-next-line no-empty-pattern
  async ({ projectId }: { projectId: number } & IAppLoaderAction, { rejectWithValue }) => {
    try {
      const response = (await axios.get)<Array<IProjectsPermissions>>(
        `${apiUrls.projects.projectUserPermissions}?filters[project][id][$in][0]=${projectId}&populate[user][populate][role]=*&populate=project`,
      );
      return { data: (await response).data };
    } catch (err) {
      console.error(err);
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err?.response?.data);
      } else {
        throw err;
      }
    }
  },
);

export const projectsSlice = createSlice({
  name: "projects",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllProjects.fulfilled, (state, { payload }) => {
      const projectsList = payload.data as Array<IProject>;
      state.all = projectsList;
    });
    builder.addCase(getProjectTemplates.fulfilled, (state, { payload }) => {
      const templatesList = payload.data as Array<ITemplateProject>;
      state.templates = templatesList;
    });
    builder.addCase(getProjectTagList.fulfilled, (state, { payload }) => {
      const projectTagList = payload.data as Array<IProjectTag>;
      state.projectTags = projectTagList;
    });
    builder.addCase(setBackgroundMusic.fulfilled, (state, { payload }) => {
      const currentProject = payload.data as IProject;
      state.currentProject = currentProject;
    });
    builder.addCase(getCurrentInteractions.fulfilled, (state, { payload }) => {
      const currentInteractions = payload.data as ICurrentProject;
      state.currentProjectInteractions = currentInteractions;
    });
    builder.addCase(getProjectSize.fulfilled, (state, { payload }) => {
      state.currentProjectInteractions.sizeInMbs = payload.data;
    });
    builder.addCase(updateProject.fulfilled, (state, { payload }) => {
      state.currentProject = payload;
      const updated = [...state.all].map((project: IProject) => {
        return Number(project.id) === Number(payload.id) ? payload : project;
      });
      state.all = updated;
    });
    builder.addCase(fetchCurrentProjectBuilds.fulfilled, (state, { payload }) => {
      state.currentProjectBuilds = payload;
    });
    builder.addCase(duplicateProject.fulfilled, (state, { payload }) => {
      const newProject = payload.data as IProject;
      const updatedProjectList = [...state.all, newProject];
      state.all = updatedProjectList;
    });
    builder.addCase(makeTemplate.fulfilled, (state, { payload }) => {
      const newProject = payload.data as IProject;
      const updatedProjectList = [...state.all, newProject];
      state.all = updatedProjectList;
    });
    builder.addCase(deleteProject.fulfilled, (state, { payload }) => {
      const updatedProjectList = [...state.all].filter(
        (project: IProject) => Number(project.id) !== Number(payload.data.id),
      ) as Array<IProject>;

      state.projectTags = [...state.projectTags].map((tag: IProjectTag) => {
        tag.projects = tag.projects.filter((p) => Number(p.id) !== Number(payload.data.id));
        return tag;
      });

      state.all = updatedProjectList;
    });
    builder.addCase(deleteMultipleProject.fulfilled, (state, { payload }) => {
      const updatedProjectList = [...state.all].filter(
        (project: IProject) => !payload.data.includes(Number(project.id)),
      ) as Array<IProject>;
      state.all = updatedProjectList;
    });
  },
  reducers: {
    setCurrentProject: (state: IProjectsState, action: PayloadAction<IProject>) => {
      const projectObject = state.all.find(
        (project: IProject) => Number(project.id) === Number(action.payload.id),
      );
      state.currentProject = projectObject || action.payload;
    },
    setCurrentProjectFromId: (state: IProjectsState, action: PayloadAction<number>) => {
      const projects = current(state).all;
      const projectObject = projects.find(
        (project: IProject) => Number(project.id) === Number(action.payload),
      );
      state.currentProject = projectObject as IProject;
    },
    setCurrentProjectPermissions: (
      state: IProjectsState,
      action: PayloadAction<IProjectsPermissions[]>,
    ) => {
      state.currentProjectPermissions = action.payload;
    },
    setCurrentTemplateId: (state: IProjectsState, action: PayloadAction<number>) => {
      state.currentTemplateId = String(action.payload) as any;
    },
    setProjectTags: (state: IProjectsState, action: PayloadAction<IProjectTag>) => {
      state.projectTags = [action.payload];
    },
    setNewProject: (state: IProjectsState, action: PayloadAction<INewProjectFormValues>) => {
      state.newProject = { ...action.payload };
    },
    setNewProjectLanguageId: (state: IProjectsState, action: PayloadAction<number>) => {
      state.newProject.languageId = action.payload;
    },
    setNewProjectEnabledLanguagesIds: (state: IProjectsState, action: PayloadAction<string[]>) => {
      state.newProject.enabledLanguagesIds = action.payload;
    },
    setCurrentBuilds: (state: IProjectsState, action: PayloadAction<IBuild[]>) => {
      state.currentProjectBuilds = action.payload;
    },
    setCurrentModeListProject: (state: IProjectsState, action: PayloadAction<boolean>) => {
      state.currentModeListProject = action.payload;
    },

    setEdited: (state: IProjectsState) => {
      state.edited = {
        name: state.currentProject.name,
        source_thumbnail: state.currentProject.source_thumbnail,
        language: state.currentProject.language?.id,
        enabledLanguagesIds: state.currentProject.enabled_languages?.map((l) => String(l.id)),
      };
    },
    setEditedName: (state: IProjectsState, action: PayloadAction<string>) => {
      state.edited.name = action.payload;
    },
    setEditedLanguage: (state: IProjectsState, action: PayloadAction<number>) => {
      state.edited.language = action.payload;
    },
    setEditedProjectEnabledLanguagesIds: (
      state: IProjectsState,
      action: PayloadAction<string[]>,
    ) => {
      state.edited.enabledLanguagesIds = action.payload;
    },
    setEditedThumbnail: (state: IProjectsState, action: PayloadAction<IMedia>) => {
      state.edited.source_thumbnail = action.payload;
    },
    clearProjects: (state: IProjectsState) => {
      (Object.keys(state) as Array<keyof typeof state>).forEach((key) => {
        (state[key] as any) = initialState[key];
      });
    },
    updateProjectThumbnail: (state: IProjectsState, action: PayloadAction<IMedia>) => {
      const bingo = (project: any) =>
        Number(project.source_thumbnail.id) === Number(action.payload.id);

      state.all = [...state.all].map((project: IProject) => {
        if (bingo(project)) {
          return {
            ...project,
            source_thumbnail: action.payload,
          };
        } else {
          return project;
        }
      });
      state.edited.source_thumbnail = action.payload;
    },
  },
});

export const TemplatesForTagSelector = ({ state, tag }: { state: RootState; tag: string }) => {
  const templates = state.projects.templates;
  const projects = state.projects.all;
  const templateTag = tag;

  const filteredProjects = projects.filter(
    (project) =>
      project.is_template &&
      project?.project_tags
        ?.map((tag) => tag.slug.toLowerCase())
        .includes(templateTag.toLowerCase()) &&
      !project.is_deleted,
  );

  const filteredTemplateInfos = templates.filter((t) => {
    filteredProjects.map((p: IProject) => Number(p.id)).includes(Number(t?.project?.id));
  });

  const templateInfo = filteredTemplateInfos.filter(
    (t) => t.language === "fr" || t.language === "custom",
  );

  return { filteredProjects, templateInfo };
};

export const projectsSelector = (state: RootState) => state.projects.all;

export const getTemplate = (state: RootState) => state.projects.templates;

export const getProjectTagListSelector = (state: RootState) => state.projects.projectTags;

export const getNewProjectSelector = (state: RootState) => state.projects.newProject;

export const getCurrentProject = (state: RootState) => state.projects.currentProject;

export const getEditedProject = (state: RootState) => state.projects.edited;

export const getCurrentProjectBuilds = (state: RootState) => state.projects.currentProjectBuilds;

export const getCurrentProjectPermissions = (state: RootState) =>
  state.projects.currentProjectPermissions;

export const getCurrentTemplateId = (state: RootState) => state.projects.currentTemplateId;

export const projectsReducer = projectsSlice.reducer;

export const getCurrentInteractionElements = (state: RootState) =>
  state.projects.currentProjectInteractions;

export const getCurrentModelistProject = (state: RootState) =>
  state.projects.currentModeListProject;

export const {
  setCurrentProject,
  setCurrentProjectFromId,
  setCurrentTemplateId,
  setProjectTags,
  setCurrentModeListProject,
  setNewProject,
  setNewProjectLanguageId,
  setNewProjectEnabledLanguagesIds,
  setCurrentProjectPermissions,
  setEdited,
  setEditedName,
  setEditedThumbnail,
  setEditedLanguage,
  setEditedProjectEnabledLanguagesIds,
  clearProjects,
  updateProjectThumbnail,
} = projectsSlice.actions;
