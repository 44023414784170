import { useDispatch, useSelector } from "react-redux";
import {
  getMapContentKeysWithPldKeys,
  getNodeById,
  loadGraph,
  relevantPldForContent,
  relevantPldsForContent,
  updateContentType,
} from "../state/graphLegacy";
import { AppDispatch, RootState } from "../../../common/state/store";
import { useEffect, useState } from "react";
import { extractFieldsFromContentType, Field } from "../util/graphFieldsExtractor";
import { Controller, useForm } from "react-hook-form";
import { IMedia } from "../../../model/unityObject";
import { ContentTypeScene } from "../util/dataTypes/ContentType";
import { PlusIcon } from "@heroicons/react/outline";
import {
  mediaSelector,
  setMediaFilter,
  setOriginalMediaGallery,
} from "../../gallery/state/gallerySlice";
import { IsLoading } from "../../../common/components/AppState/IsLoading";
import { LoadingState, setIsLoading } from "../../../common/state/slice/appStateSlice";
import {
  closeConfirmationModal,
  openConfirmationModal,
  closeStandardModal,
  openStandardModal,
} from "../../../common/state/slice/modal/modalSlice";
import GetIconNodeForm from "./GetIconNode";
import { DynamicField, DynamicMedia } from "./DynamicField";
import ModalGallery from "./ModalGallery";
import { deleteNode, getCurrentProject } from "../../projects/state/projectsSlice";
import { useTranslation } from "react-i18next";
import { MdDeleteOutline } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from "../../../common/components/Tooltip/Tooltip";
import { menuItem, menuItems } from "../../../common/components/Icon/ThreeDotsDropdownMenu";
import {
  getCurrentElements,
  getCurrentProjectGroupWeight,
  getProjectGroupSize,
  getSelectedProjectGroup,
} from "../../projectGroups/state/projectGroupsSlice";
import ModalConfirmation from "../../../common/components/Modal/ModalConfirmation";
import canSaveProject from "../util/canSaveProject";
import { FooterButtons } from "../../../common/components/FooterButtons";
import StartSceneTickbox from "./StartSceneTickbox";
import { useCallbackPrompt } from "../../../common/util/useCallbackPrompt";
import ModalConfirmBeforeNavigatingOut from "../../../common/components/Modal/ModalConfirmBeforeNavigatingOut";
import CustomXIcon from "src/common/components/CustomXIcon/CustomXIcon";
import buildUpdatedProjectLanguageData from "../util/buildUpdatedProjectLanguageData";
import { MediaField } from "../util/graphFieldsExtractor";
import { setAlert } from "src/features/alert/state/alertsSlice";

const NODE_COMPONENT_ID = "nodeComponentId";
const MAX_PROJECT_GROUP_MEDIA_SIZE_IN_BYTES = 5368709120;

const Node = ({
  nodeId,
  contentType,
  mode,
}: {
  nodeId: string;
  contentType: string;
  mode: "edit" | "view";
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const mediasList = useSelector(mediaSelector);
  const currentProject = useSelector(getCurrentProject);
  const currentGroupProject = useSelector(getSelectedProjectGroup);
  const currentElements = useSelector(getCurrentElements);
  const mapContentKeysWithPldKeys = useSelector(getMapContentKeysWithPldKeys);
  const node = useSelector((state: RootState) => getNodeById(state, nodeId, contentType));
  const projectLanguageDatasOfThisContent = useSelector(
    relevantPldsForContent(node?.object.content),
  );
  const currentProjectGroupWeight = useSelector(getCurrentProjectGroupWeight);

  const [stopPlayingMedia, setStopPlayingMedia] = useState(false);
  const [fieldsToDisplay, setFieldsToDisplay] = useState<Field[]>([]);
  const [enhancedFieldsToDisplay, setEnhancedFieldsToDisplay] = useState<IEnhancedFieldToDisplay[]>(
    [],
  );
  const [currentMediaField, setCurrentMediaField] = useState<string>("");
  const [areButtonsDisabled, setAreButtonDisabled] = useState(false);
  const [isStartScene, setIsStartScene] = useState(node && node.object.is_start_scene);
  const [hasMediaChanged, setHasMediaChanged] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isComputingPgSize, setIsComputingPgSize] = useState(false);
  const [isOverProjectGroupMediaWeightLimit, setIsOverProjectGroupMediaWeightLimit] = useState(
    currentProjectGroupWeight > MAX_PROJECT_GROUP_MEDIA_SIZE_IN_BYTES,
  );

  interface IEnhancedFieldToDisplay {
    fields: MediaField;
    relevantSourceMedia?: IMedia;
  }

  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    control,
    watch,
    formState: { dirtyFields },
  } = useForm<any>();
  const nameCantBeEmpty = watch("name") ? watch("name").length > 0 : false;
  const contentId = node?.object.content;
  const relevantPld = useSelector(relevantPldForContent(contentId)).projectLanguageData;

  const currentFilter = useSelector((state: RootState) => state.media.currentFilter);
  const onSubmit = () => {
    // Maybe this has some use, but atm (1/08/22) only thing it does is cause a bug (make API call on image click)
    //onUpdateNode();
  };

  useEffect(() => {
    dispatch(closeStandardModal("modal-gallery"));
    return setHasMediaChanged(false);
  }, []);

  useEffect(() => {
    const updatedEnhancedFields: IEnhancedFieldToDisplay[] = fieldsToDisplay
      .map((field: MediaField) => {
        const relevantSourceMedia = mediasList.find(
          (m: IMedia) => Number(m.id) === Number(field.currentValue),
        );
        return {
          fields: field,
          relevantSourceMedia,
        };
      })
      .filter((field) => field.fields.type === "media");

    setEnhancedFieldsToDisplay(updatedEnhancedFields);
  }, [fieldsToDisplay, mediasList, relevantPld]);

  useEffect(() => {
    dispatch(setIsLoading({ componentId: NODE_COMPONENT_ID, loading: LoadingState.Idle }));
    if (node) {
      setFieldsWithPldValues({ payload: extractFieldsFromContentType(node) });
    }
  }, [node?.object.id]);

  const hasSomethingChanged = () => {
    if (isSaved) return false;
    const objectKeys = Object.keys(dirtyFields);
    const butWithoutMediaFields = objectKeys.filter((k) => !k.includes("source_"));
    const hasSmthChanged = Boolean(
      butWithoutMediaFields.length > 0 ||
        hasMediaChanged ||
        (node && node.object.is_start_scene !== isStartScene),
    );
    return hasSmthChanged;
  };

  const prettyLanguageName = () => {
    const languagePk = currentProject.language?.id
      ? currentProject?.language.id
      : currentProject?.enabled_languages.length > 0
      ? currentProject.enabled_languages[0].id
      : Number(currentProject?.language);
    if (languagePk === 2) {
      return t("general.languages.english");
    }
    if (languagePk === 3) {
      return t("general.languages.italian");
    }
    return t("general.languages.french");
  };

  const onUpdateNode = () => {
    if (node) {
      setValue("is_start_scene", isStartScene);

      const changes = getValues();
      setHasMediaChanged(false);

      setAreButtonDisabled(true);
      dispatch(setIsLoading({ componentId: NODE_COMPONENT_ID, loading: LoadingState.Loading }));
      setIsStartScene(node && node.object.is_start_scene);
      setIsSaved(true);

      // Added 11/06/24: handle PLD update there
      const updatedProjectLanguageData = buildUpdatedProjectLanguageData({
        node,
        changes,
        relevantPld,
        mapContentKeysWithPldKeys,
      });

      dispatch(
        updateContentType({
          componentId: NODE_COMPONENT_ID,
          contentType: node as ContentTypeScene,
          updatedProjectLanguageData,
          changes,
          callback: () => {
            dispatch(
              setIsLoading({ componentId: NODE_COMPONENT_ID, loading: LoadingState.Success }),
            );
            dispatch(loadGraph(currentProject.id));

            if (location.pathname.includes("templates")) {
              navigate(`/templates/${currentProject.id}`);
            } else {
              navigate("../graph");
            }
          },
          allMedias: mediasList,
        }),
      );
    }
  };

  // HANDLE PROJECT LANGUAGE DATA MESS
  const setFieldsWithPldValues = ({
    payload,
    isHappeningAfterMediaUpdate = false,
  }: {
    payload: Field[];
    isHappeningAfterMediaUpdate?: boolean;
  }) => {
    let updatedPld = {
      ...relevantPld,
    };
    //  dispatch(updateRelevantPld({ contentId: relevantPld.content, updatedPld }));

    const payloadUpdatedWithPldValues = payload.map((field: MediaField) => {
      const pldKey = getRelevantPldKey({
        contentKey: field.fieldName,
        mapContentKeysWithPldKeys: mapContentKeysWithPldKeys,
        contentType,
      });
      const relevantMediaIdInPldBeforeUpdate = pldKey ? relevantPld?.[pldKey] : null;
      if (pldKey && relevantMediaIdInPldBeforeUpdate !== field.currentValue) {
        updatedPld = {
          ...updatedPld,
          [pldKey]: field.currentValue,
        };
      }
      return {
        ...field,
        currentValue:
          relevantMediaIdInPldBeforeUpdate && !isHappeningAfterMediaUpdate
            ? relevantMediaIdInPldBeforeUpdate
            : field.currentValue,
      };
    });
    setFieldsToDisplay(payloadUpdatedWithPldValues);
  };

  const getRelevantPldKey = ({
    contentKey,
    mapContentKeysWithPldKeys,
    contentType,
  }: {
    contentKey: string;
    mapContentKeysWithPldKeys: Record<string, [string, string][]>;
    contentType: string;
  }): string | null => {
    const mapEntries = Object.entries(mapContentKeysWithPldKeys);
    const match = mapEntries.find((entry) => entry[0].includes(contentType));

    if (match) {
      const keyMap = match[1].reduce((acc, [index0, index1]) => {
        acc[index1] = index0;
        return acc;
      }, {});
      return keyMap[contentKey];
    } else {
      console.warn(
        "NO match in getRelevantPldKey; contentKey: ",
        contentKey,
        ".. contentType: ",
        contentType,
      );
    }
    return null;
  };

  const onMediaChange = (selectedMedia: IMedia | null, mediaField?: string | null) => {
    // Until next comment, it is trash code to compute & compare medias size in bytes before & after
    const isolateMediaFieldsMedia = (fieldsToDisplay: any[]) => {
      return [...mediasList].filter((m) => {
        const mediaFields = fieldsToDisplay
          .filter((ftd) => ftd.fieldName.includes("source_"))
          .map((ftd) => Number(ftd.currentValue));
        return mediaFields.includes(Number(m.id));
      });
    };

    const sumOfSizeInBytes = (mediaFieldsArr: any[]) =>
      mediaFieldsArr.map((m) => Number(m.size_in_bytes)).reduce((a, b) => a + b, 0);

    const mediaFieldsMediasBefore = isolateMediaFieldsMedia(fieldsToDisplay);

    const sumOfSizeInBytesBeforeChangeForThisNode = mediaFieldsMediasBefore
      .map((m) => Number(m.size_in_bytes))
      .reduce((a, b) => a + b, 0);

    const fieldsToDisplayAfterChange = fieldsToDisplay.map((ftd) => {
      if (ftd.fieldName === currentMediaField) {
        return { ...ftd, currentValue: Number(selectedMedia?.id) };
      }
      return ftd;
    });

    const mediaFieldsMediasAfter = isolateMediaFieldsMedia(fieldsToDisplayAfterChange);
    const sumOfSizeInBytesAfterChangeForThisNode = sumOfSizeInBytes(mediaFieldsMediasAfter);

    if (currentElements?.length) {
      setIsComputingPgSize(true);
      dispatch(
        getProjectGroupSize({
          projectsIds: currentElements.map((pge) => pge.project.id).join(","),
        }),
      )
        .then((res: any) => {
          const projectGroupSize = res.payload.Data;
          setIsOverProjectGroupMediaWeightLimit(
            projectGroupSize -
              sumOfSizeInBytesBeforeChangeForThisNode +
              sumOfSizeInBytesAfterChangeForThisNode >
              MAX_PROJECT_GROUP_MEDIA_SIZE_IN_BYTES,
          );
        })
        .finally(() => {
          setIsComputingPgSize(false);

          setIsOverProjectGroupMediaWeightLimit(
            currentProjectGroupWeight -
              sumOfSizeInBytesBeforeChangeForThisNode +
              sumOfSizeInBytesAfterChangeForThisNode >
              MAX_PROJECT_GROUP_MEDIA_SIZE_IN_BYTES,
          );
        });
    }

    // Old stuff
    const mf = mediaField && mediaField !== "" ? mediaField : currentMediaField;
    const selectedMediaId = selectedMedia?.id ? selectedMedia?.id : null;
    setValue(mf, selectedMediaId);
    const updatedFields: any = fieldsToDisplay.map((field) => {
      if (field.fieldName === mf) {
        if (Number(field.currentValue) !== Number(selectedMedia?.id)) {
          setHasMediaChanged(true);
        }
        return { ...field, currentValue: selectedMedia?.id };
      }
      return field;
    });
    setFieldsWithPldValues({ payload: updatedFields, isHappeningAfterMediaUpdate: true });
    dispatch(closeStandardModal("modal-gallery"));
  };

  const onRemove = () => {
    setAreButtonDisabled(true);
    dispatch(closeConfirmationModal("remove-node-confirmation"));
    dispatch(setIsLoading({ componentId: NODE_COMPONENT_ID, loading: LoadingState.Loading }));
    dispatch(deleteNode({ contentId: node?.object.content })).then(() => {
      if (location.pathname.includes("templates")) {
        navigate(`/templates/${currentProject.id}`);
      } else {
        navigate(`/project-groups/${currentGroupProject.id}/list/${currentProject.id}/graph`);
      }
    });
  };

  const getCanDelete = () => {
    if (node?.object.is_start_scene) {
      return { canDelete: false, reason: "pages.project.editNode.canNotDeleteStartScene" };
    }
    if (currentProject.status === "Published" || currentProject.status === "PublishWaiting") {
      return { canDelete: false, reason: "pages.project.editNode.noEditableProject" };
    }

    return { canDelete: true, reason: "" };
  };
  const canDelete = getCanDelete();

  const canSave = canSaveProject(currentProject);

  const model3dMenuItems: menuItems = [
    { menuItemText: t("general.delete"), icon: "DocumentRemoveIcon", itemId: "deleteEnv3d" },
  ];

  const handleOpenGallery = (enhancedField: any, menuItem: menuItem | null) => {
    const mediaType: any = enhancedField.fields.type === "media" && enhancedField.fields.mediaType;
    dispatch(setMediaFilter({ ...currentFilter, mediaType }));
    setCurrentMediaField(enhancedField.fields.fieldName);
    dispatch(setOriginalMediaGallery(enhancedField.fields.currentValue));

    if (menuItem?.itemId === "deleteEnv3d") {
      onMediaChange(null, "source_model3d");
    } else {
      dispatch(openStandardModal("modal-gallery"));
    }
  };

  const onRemoveButtonClick = () => {
    dispatch(openConfirmationModal("remove-node-confirmation"));
  };

  let deleteButton = (
    <button
      className="btn-alternative-outline mr-6 pl-2 pr-4 flex"
      disabled={!canDelete.canDelete || areButtonsDisabled || !canSave.canSave}
      onClick={onRemoveButtonClick}
    >
      <MdDeleteOutline
        className={`${canDelete.canDelete ? "" : "opacity-50 cursor-not-allowed"}`}
        size={30}
      />
      <span className="mt-1">{t("general.delete")}</span>
    </button>
  );

  if (!canDelete.canDelete) {
    deleteButton = (
      <Tooltip classNameChildren="right-1" message={t(canDelete.reason)}>
        {deleteButton}
      </Tooltip>
    );
  }

  if (node) {
    const detectTypeForAssetNode = (model: string) => {
      switch (model) {
        case "content-audio":
          return "sound";
        default:
          return "control";
        case "content-button-1":
        case "content-button-2":
        case "content-quizz-1":
        case "content-quizz-2":
        case "content-quizz-3":
        case "content-scoring-general":
        case "content-text":
        case "content-tuto":
        case "content-speech-to-check":
        case "content-clean":
        case "content-yes-no-question":
        case "content-scoring-scene":
        case "content-photo-catch":
        case "content-quizz-sort":
        case "content-quizz-image-multiple":
          return "control";
        case "content-thumbnail":
        case "content-onboarding":
        case "content-pdf-viewer":
          return "image";
        case "content-hotspot-scene-video":
        case "content-hotspot-scene-image":
        case "content-vr-locator":
        case "content-zone-detect":
        case "content-zone-sort":
          return "location";
        case "content-video-2-d":
          return "video";
        case "content-3d-viewer":
          return "model3d";
        case "content-blur":
        case "content-fire":
          return "effect";
      }
    };

    const typeInteractionNameToDispay = node.modelName.replaceAll("-", " ").replace("content", "");
    const DisplayFormNoMedia =
      enhancedFieldsToDisplay.length === 0 &&
      !enhancedFieldsToDisplay.find((elt) => elt.fields.fieldName === "source_model3d");

    const fieldContentScene = () => {
      const isSourceModel3d = !enhancedFieldsToDisplay.find(
        (elt) => elt.fields.fieldName === "source_model3d",
      )?.fields.currentValue;

      if (node.modelName === "content-scene") {
        if (mode === "edit") {
          if (isSourceModel3d) {
            return (
              <div>
                <h2 className="text-xl w-full max-w-7xl mx-auto mt-6">{t("general.3dEnv")}</h2>
                <div>
                  <button
                    className="btn-alternative-outline mt-2.5 button-enviroment3d text-green-500"
                    onClick={() =>
                      handleOpenGallery(
                        enhancedFieldsToDisplay.find(
                          (elt) => elt.fields.fieldName === "source_model3d",
                        ),
                        null,
                      )
                    }
                    disabled={areButtonsDisabled || !canSave.canSave}
                  >
                    <PlusIcon className="h-6 w-6 ml-36 mb-5" />
                    {t("general.add3dEnv")}
                  </button>
                </div>
              </div>
            );
          } else {
            return <></>;
          }
        } else {
          return (
            <div>
              <h2 className="text-xl w-full max-w-7xl mx-auto mt-6 mb-4">{t("general.3dEnv")}</h2>
              <p className="text-center text-gray-500">{t("general.no3dEnv")}</p>
            </div>
          );
        }
      } else {
        return <></>;
      }
    };

    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
      hasSomethingChanged(),
    );

    const nextDisabledTooltipText = () => {
      if (isComputingPgSize) {
        return t("tooltip.computingPgSize");
      }
      if (node.modelName === "content-scene") {
        if (watch("name") && watch("name").length === 0) {
          return t("tooltip.pleaseNameTheScene");
        }
        // search the media.all & if it doesnt include nodeToEdit.object.source_media display specific message
        const mediaExists = mediasList.find(
          (media) => Number(media.id) === node.object?.source_media,
        );
        if (!mediaExists) {
          return t("tooltip.mediaNeeded");
        }
      }

      if (!isComputingPgSize && isOverProjectGroupMediaWeightLimit) {
        return t("pages.project.editNode.overProjectGroupMediaWeightLimit");
      } else {
        return t(canSave.reason);
      }
    };

    useEffect(() => {
      if (node.modelName === "content-scene") {
        // if content-scene and media was video and it now is image, then display specific warning about pld
        // cf WIXAR-3819  https://wixar.atlassian.net/browse/WIXAR-3819?atlOrigin=eyJpIjoiMjNhY2M2ODhmZTNhNDEzZTk2ZDdhZWU2MjFkMzI0MmIiLCJwIjoiaiJ9
        const changes = getValues();
        const newlySelectedMedia = mediasList.find(
          (media) => Number(media.id) === Number(changes.source_media),
        );
        const isCurrentSceneTypeVideo = node.object.scene_type === "video360";
        const isCurrentSceneTypeImage = node.object.scene_type === "image360";
        const isNewSelectedMediaTypeDifferentFromPreviousOne =
          newlySelectedMedia &&
          ((isCurrentSceneTypeVideo && newlySelectedMedia.type === "image") ||
            (isCurrentSceneTypeImage && newlySelectedMedia.type === "video"));

        if (isNewSelectedMediaTypeDifferentFromPreviousOne) {
          // only display warning when relevant
          if (projectLanguageDatasOfThisContent.length > 1) {
            dispatch(
              setAlert({
                type: "warning",
                msg: t("pages.project.editNode.warningSceneMediaTypeChangePldsAreBeingOverriden"),
                subMsg: t(
                  "pages.project.editNode.warningSceneMediaTypeChangePldsAreBeingOverridenSubMsg",
                ),
                customTimeoutInMs: 30000,
              }),
            );
          }
        }
      }
    }, [JSON.stringify(enhancedFieldsToDisplay)]);

    return (
      <>
        <ModalGallery nodeId={nodeId} contentType={contentType} onMediaChange={onMediaChange} />
        <ModalConfirmation
          title={t(
            `pages.project.editNode.${
              node.modelName === "content-scene"
                ? "deleteSceneConfirmationModal"
                : "deleteInteractionConfirmationModal"
            }.header`,
          )}
          text={t(
            `pages.project.editNode.${
              node.modelName === "content-scene"
                ? "deleteSceneConfirmationModal"
                : "deleteInteractionConfirmationModal"
            }.body`,
          )}
          onClickSubmit={onRemove}
          id={"remove-node-confirmation"}
        />

        <div className="flex flex-col w-full h-full mt-4 mx-auto">
          <IsLoading componentId={NODE_COMPONENT_ID} spinnerPlaceholder>
            <form className={"relative w-full h-full"} onSubmit={handleSubmit(onSubmit)}>
              <div className="max-w-3xl mx-auto ">
                <div className="w-full flex flex-col">
                  <div className=" flex mb-2 items-center justify-center font-bold h-[15%]">
                    <h2 className="text-2xl ">
                      {t(mode === "edit" ? "general.edit" : "general.view")}
                    </h2>
                  </div>
                  <div className=" flex mb-6 items-center justify-center h-[15%]">
                    <h2 className="text-md italic">{`${t(
                      "pages.editProjectLanguage.projectMainLanguage",
                    )}: ${prettyLanguageName()}`}</h2>
                  </div>
                  <div
                    className={`${
                      DisplayFormNoMedia ? "max-w-xs items-center justify-center ml-56" : "max-w-sm"
                    } h-[75%]`}
                  >
                    <div>
                      {fieldsToDisplay &&
                        fieldsToDisplay.map((field: Field, index: number) => (
                          <DynamicField
                            key={index}
                            field={field}
                            register={register}
                            disabled={mode !== "edit"}
                            maxLength={24}
                            className="dynamic-field"
                          />
                        ))}
                    </div>
                  </div>
                </div>

                <div className={`${DisplayFormNoMedia ? "ml-11" : ""} flex pt-2 w-full`}>
                  {enhancedFieldsToDisplay.length > 0 &&
                    enhancedFieldsToDisplay.map((enhancedField, index: number) => {
                      let isEnv3d = false;
                      if (
                        enhancedField.fields.fieldName === "source_model3d" &&
                        node.modelName === "content-scene"
                      ) {
                        if (!enhancedField.fields.currentValue) {
                          return <></>;
                        } else {
                          isEnv3d = true;
                        }
                      }

                      return (
                        <div
                          key={`${mode}-node-${index}`}
                          className={`mt-2 w-80 mr-9 ${isEnv3d ? "w-80 h-72" : ""}`}
                        >
                          {isEnv3d ? (
                            <h2 className="text-xl w-full max-w-7xl mx-auto mb-4 mt-4">
                              {t("general.3dEnv")}
                            </h2>
                          ) : (
                            <h2 className="text-xl w-full max-w-7xl mx-auto mb-4 mt-4">
                              {t("general.backgroundMedia")}
                            </h2>
                          )}

                          <Controller
                            control={control}
                            name={enhancedField.fields.fieldName}
                            render={({ field }) => (
                              <DynamicMedia
                                className="dynamic-media"
                                field={field}
                                fieldType={enhancedField.fields.type}
                                register={register}
                                stopPlayingMedia={stopPlayingMedia}
                                handleButtonClick={(_mediaId, item) => {
                                  setStopPlayingMedia(!stopPlayingMedia);
                                  if (mode === "edit") {
                                    handleOpenGallery(enhancedField, item);
                                  } else if (enhancedField.relevantSourceMedia?.type !== "pdf") {
                                    dispatch(
                                      openStandardModal(
                                        `preview_${enhancedField.relevantSourceMedia?.id}`,
                                      ),
                                    );
                                  }
                                }}
                                currentMedia={enhancedField.fields.currentValue as number}
                                media={enhancedField.relevantSourceMedia as IMedia}
                                customMenuItems={mode === "edit" && isEnv3d ? model3dMenuItems : []}
                                disabled={mode !== "edit"}
                                showThreeDotsMenu={mode === "edit"}
                                CustomRemoveButton={
                                  mode === "edit" && isEnv3d
                                    ? {
                                        component: CustomXIcon,
                                        customProps: {
                                          className:
                                            "stroke-black w-6 h-6 absolute right-3 top-4 hover:stroke-gray-600",
                                          onClick: (e: Event) => {
                                            e.stopPropagation();
                                            onMediaChange(null, "source_model3d");
                                          },
                                          tooltipText: t("general.remove3dEnv"),
                                        },
                                      }
                                    : undefined
                                }
                              />
                            )}
                          />
                        </div>
                      );
                    })}
                  {
                    //icon type iteraction media/
                  }
                  {enhancedFieldsToDisplay.length > 0 &&
                    !enhancedFieldsToDisplay.find(
                      (elt) => elt.fields.fieldName === "source_model3d",
                    ) && (
                      <div className=" realative mt-2 w-80  mr-9">
                        <h2 className="text-xl w-full max-w-7xl mx-auto mb-4 mt-4">
                          {t("general.typeInteraction")}
                        </h2>
                        <div className="pt-3">
                          <h4 className="text-center text-xl text-black capitalize ">
                            {typeInteractionNameToDispay}
                          </h4>

                          <GetIconNodeForm
                            type={detectTypeForAssetNode(String(node.modelName))}
                            height={200}
                            width={500}
                            viewBox={"-3 17 70 5"}
                          />
                        </div>
                      </div>
                    )}
                  {/* icon type iteraction no media  */}
                  {enhancedFieldsToDisplay.length === 0 &&
                    !enhancedFieldsToDisplay.find(
                      (elt) => elt.fields.fieldName === "source_model3d",
                    ) && (
                      <div className="ml-44 mt-2 w-80  mr-9">
                        <h2 className="text-xl pl-1.5 w-full max-w-7xl mx-auto mb-4 mt-4">
                          {t("general.typeInteraction")}
                        </h2>
                        <div className="pt-6">
                          <h4
                            className={`${
                              String(node.modelName) === "content-hotspot-scene-video"
                                ? "pl-20"
                                : "pl-32"
                            } text-xl text-black capitalize`}
                          >
                            {typeInteractionNameToDispay}
                          </h4>
                          <GetIconNodeForm
                            type={detectTypeForAssetNode(String(node.modelName))}
                            height={200}
                            width={500}
                            viewBox={"-5 17 70 3"}
                          />
                        </div>
                      </div>
                    )}
                  {fieldContentScene()}
                </div>
                {node.modelName === "content-scene" && mode === "edit" && (
                  <StartSceneTickbox
                    disabled={!canSave.canSave}
                    wasStartScene={node.object.is_start_scene}
                    isStartScene={isStartScene}
                    setIsStartScene={() => {
                      setIsStartScene(!isStartScene);
                    }}
                  />
                )}
              </div>
            </form>
          </IsLoading>
          {mode === "edit" && (
            <FooterButtons
              cancelCb={() => {
                if (location.pathname.includes("templates")) {
                  navigate(`/templates/${currentProject.id}`);
                } else {
                  navigate("../graph");
                }
              }}
              cancelText={t("general.cancel")}
              nextCb={onUpdateNode}
              nextText={t("general.save")}
              nextDisabledTooltipText={nextDisabledTooltipText()}
              nextDisabled={
                areButtonsDisabled ||
                !canSave.canSave ||
                !hasSomethingChanged() ||
                !nameCantBeEmpty ||
                isComputingPgSize ||
                isOverProjectGroupMediaWeightLimit
              }
              customButton={deleteButton}
              customButtonIndex={1}
            />
          )}
        </div>
        {mode === "edit" && (
          <ModalConfirmBeforeNavigatingOut
            showDialog={showPrompt}
            cancelNavigation={cancelNavigation}
            confirmNavigation={confirmNavigation}
          />
        )}
      </>
    );
  } else {
    return <>Cant find the node to {mode}</>;
  }
};

export default Node;
