import { ButtonSquare } from "../../common/components/Button";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteProject,
  getCurrentModelistProject,
  getProjectPermissionsFromServer,
  getProjectTemplates,
  setCurrentModeListProject,
  setCurrentProject,
  setCurrentProjectFromId,
  setCurrentProjectPermissions,
  setEdited as setEditedProject,
} from "../../features/projects/state/projectsSlice";
import ProjectList from "../../features/projects/components/ProjectList";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../common/state/hooks";
import { IProject, IProjectGroupElement } from "../../model/model";
import {
  getCurrentElements,
  getCurrentProjectForSearch,
  getSelectedProjectGroup,
  IProjectOrder,
  removeElementsOfProject,
  reorderProjects,
  setCurrentElements,
  setSelectedProjectGroupFromId,
} from "../../features/projectGroups/state/projectGroupsSlice";
import { IsLoading } from "../../common/components/AppState/IsLoading";
import { PROJECT_GROUP_LIST_COMPONENT_ID } from "../../features/projectGroups/components/ProjectGroupList";
import handleThreeDotsButtonClickForProjectsAndProjectGroups from "../../common/util/handleThreeDotsButtonClickForProjectsAndProjectGroups";
import {
  closeConfirmationModal,
  openConfirmationModal,
} from "../../common/state/slice/modal/modalSlice";
import ModalConfirmation from "../../common/components/Modal/ModalConfirmation";
import { useTranslation } from "react-i18next";
import InfoButton from "../../common/components/InfoButton/InfoButton";
import ProjectListChangeOrder from "../../features/projects/components/ProjectListChangeOrder";
import OrderIcon from "../../common/components/Icon/OrderIcon";
import { useCallbackPrompt } from "../../common/util/useCallbackPrompt";
import ModalConfirmBeforeNavigatingOut from "../../common/components/Modal/ModalConfirmBeforeNavigatingOut";
import { ViewGridIcon, ViewListIcon } from "@heroicons/react/outline";
import ModeListProjectGroup from "../ProjectGroups/ModeListProjectAndProjectsGroup";
import OnboardingComponent from "../../common/util/OnBoardingComponent";
import stepsOnboarding from "../../common/util/OnboardingTour";

export const PROJECT_LIST_COMPONENT_CLONE_LOADING_ID = "projectListWhenProjectGroupHasBeenSelected";

const ProjectsList = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isCheck, setIsCheck] = useState<Array<any>>([]);

  const selectedProjectGroup = useSelector(getSelectedProjectGroup);
  const selectedProjectElementsNoFilter = useSelector(getCurrentProjectForSearch);

  const selectedProjectElements = useSelector(getCurrentElements);

  const [clickedProjectId, setClickedProjectId] = useState(0);
  const [isChangeOrderMode, setIsChangeOrderMode] = useState(false);
  const [projectsNewOrder, setProjectsNewOrder] = useState<Array<IProjectOrder>>([]);

  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(isChangeOrderMode);

  useEffect(() => {
    dispatch(getProjectTemplates({ componentId: "projectTemplateComponent" }));
  }, []);

  const navigate = useNavigate();

  /* Sort elements by 'project_group_element.order' */
  const sortedProjectGroupElements = [...selectedProjectElements]
    .sort((a: IProjectGroupElement, b: IProjectGroupElement) => {
      return a.order - b.order;
    })
    .map((pge: IProjectGroupElement) => {
      return pge.project as any;
    }) as IProject[];

  const sortedProjectGroupElementsNoFilter = [...selectedProjectElementsNoFilter]
    .sort((a: IProjectGroupElement, b: IProjectGroupElement) => {
      return a.order - b.order;
    })
    .map((pge: IProjectGroupElement) => {
      return pge.project as any;
    }) as IProject[];

  const handleClick = (project: IProject) => {
    dispatch(setCurrentProject(project));

    dispatch(getProjectPermissionsFromServer({ projectId: Number(project.id) })).then((res) => {
      dispatch(setCurrentProjectPermissions((res.payload as any).data));
    });

    navigate(`/project-groups/${selectedProjectGroup.id}/list/${project.id}/graph`);
  };

  const setOrder = (tab: Array<IProjectOrder>) => {
    setProjectsNewOrder(tab);
  };

  const onSaveOrderClick = () => {
    dispatch(
      reorderProjects({
        orders: projectsNewOrder,
        componentId: PROJECT_LIST_COMPONENT_CLONE_LOADING_ID,
      }),
    ).then((res: any) => {
      dispatch(setCurrentElements(res.payload.data));
      setIsChangeOrderMode(false);
    });
  };
  //grid list components
  const modeListCurrentState = useSelector(getCurrentModelistProject);

  const onChangeGridMedia = () => {
    dispatch(setCurrentModeListProject(!modeListCurrentState));
  };
  const setEditedProjectId = (id: number) => {
    dispatch(setCurrentProjectFromId(Number(id)));
    dispatch(setEditedProject());
  };

  useEffect(() => {
    // Gros bug du 11 septembre 2024 (communément appelé "Le 11 Septembre"): si l'user annule, il faut rétablir le vrai nom du PG
    dispatch(setSelectedProjectGroupFromId(selectedProjectGroup.id));
  }, []);

  return (
    <>
      <InfoButton relevantArticle="aboutModules" haveButtonToOnboarding />
      <IsLoading
        componentId={PROJECT_GROUP_LIST_COMPONENT_ID}
        showSuccess={false}
        spinnerStyle={{ top: "30vh" }}
      >
        <OnboardingComponent
          id={"aboutModules"}
          relevantArticle="aboutModules"
          steps={stepsOnboarding().onboardingAboutModules}
        />
        <div className="fixed top-20 z-40 right-2 mr-5 mt-2">
          <button onClick={onChangeGridMedia}>
            {modeListCurrentState ? (
              <ViewListIcon className="w-7 h-7 stroke-green-500 mr-3" />
            ) : (
              <ViewGridIcon className="w-7 h-7 stroke-green-500 mr-3" />
            )}
          </button>
        </div>
        {modeListCurrentState ? (
          <div className="block px-6 w-full py-6">
            {selectedProjectElements.length > 0 && (
              <div className="mb-4 w-[95%] flex justify-end">
                {isChangeOrderMode ? (
                  <>
                    <button
                      className="btn-alternative-outline mr-2"
                      onClick={() => setIsChangeOrderMode(false)}
                    >
                      {t("general.cancel")}
                    </button>
                    <button className="btn-primary-fill" onClick={onSaveOrderClick}>
                      {t("general.save")}
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="btn-primary-fill flex"
                      onClick={() => setIsChangeOrderMode(true)}
                      disabled={selectedProjectElements.length <= 1}
                    >
                      <OrderIcon stroke="white" />
                      <p className="ml-1">{t("pages.projects.reorderProjects")}</p>
                    </button>
                  </>
                )}
              </div>
            )}

            <div className="inline-flex justify-between w-full">
              <div className="mr-6">
                <span>
                  {isChangeOrderMode ? (
                    <div className="rounded-lg font-medium w-full py-2 px-3 border">
                      <div className="w-36 h-28 text-center">
                        <p>{t("pages.projects.reorderProjectsInfo")}</p>
                      </div>
                    </div>
                  ) : (
                    <div className="onboardingAboutModules-step-1">
                      <ButtonSquare
                        variant="btn-primary-fill"
                        iconColor="stroke-white"
                        onClick={() => navigate(`/project-groups/${selectedProjectGroup.id}/new`)}
                        text={t("pages.project.newProject")}
                      />
                    </div>
                  )}
                </span>
              </div>
              {isChangeOrderMode ? (
                <ProjectListChangeOrder
                  setOrder={setOrder}
                  projects={sortedProjectGroupElementsNoFilter}
                />
              ) : (
                <ProjectList
                  setEditedProjectId={setEditedProjectId}
                  handleThreeDotButtonClick={(e: any) => {
                    setClickedProjectId(e.itemId);
                    dispatch(setCurrentProjectFromId(Number(e.itemId)));
                    handleThreeDotsButtonClickForProjectsAndProjectGroups({
                      payload: e,
                      deleteCallback: () => {
                        dispatch(openConfirmationModal("deleteProjectConfirmation"));
                      },
                      t,
                      navigate,
                    });
                  }}
                  handleClick={handleClick}
                  projects={sortedProjectGroupElements}
                />
              )}
            </div>
          </div>
        ) : (
          <div
            className={`overflow-y-auto personalize-scroll-visible  ${
              isCheck.length > 0 ? "mt-10" : ""
            } `}
          >
            <div className="block w-full overflow-y-auto personalize-scroll-visible ">
              <ModeListProjectGroup
                setIsCheck={setIsCheck}
                isCheck={isCheck}
                isProjectList
                arrayItemsForList={sortedProjectGroupElementsNoFilter}
                setClickedProjectGroupId={setClickedProjectId}
                handleClick={handleClick}
                clickedProjectGroupId={clickedProjectId}
              />
            </div>
          </div>
        )}
      </IsLoading>

      <ModalConfirmation
        id="deleteProjectConfirmation"
        onClickSubmit={() => {
          setIsCheck([]);
          dispatch(closeConfirmationModal("deleteProjectConfirmation"));
          dispatch(
            deleteProject({ id: clickedProjectId, componentId: `project-${clickedProjectId}` }),
          ).then((res: any) => {
            const idOfDeletedProject = Number(res.payload.data.id);
            // before complaining about dispatching in dispatch, please see https://github.com/reduxjs/redux-toolkit/issues/167#issuecomment-1114010198
            dispatch(removeElementsOfProject(idOfDeletedProject));
          });
        }}
        title={t("pages.projects.deletionModal.title")}
        text={t("pages.projects.deletionModal.body")}
        componentId={PROJECT_GROUP_LIST_COMPONENT_ID}
      />

      <ModalConfirmBeforeNavigatingOut
        showDialog={showPrompt}
        cancelNavigation={cancelNavigation}
        confirmNavigation={confirmNavigation}
      />
    </>
  );
};

export default ProjectsList;
